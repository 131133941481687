<template>
  <Page color="success" title="Termine">
    <template slot="icons">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="white" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list subheader>
          <v-subheader>Einstellungen</v-subheader>
          <v-list-item>
            <v-switch
              inset
              v-model="$root.settings.showIcal"
              label="Ical-links anzeigen"
            ></v-switch>
          </v-list-item>
          <v-divider></v-divider>
          <v-subheader>Links</v-subheader>
          <v-list-item :to="{ name: 'ICalendar' }"
            ><v-list-item-icon
              ><v-icon>mdi-calendar-sync</v-icon></v-list-item-icon
            >
            <v-list-item-content>
              <v-list-item-title> Kalender-Abos </v-list-item-title>

              <v-list-item-subtitle
                >Termine und Ferien für Kalender-App
                synchronisieren</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <portal-target name="more"> </portal-target>
        </v-list>
      </v-menu>
    </template>

    <template slot="extension">
      <v-tabs
        slider-color="white"
        background-color="success darken-1"
        :align-with-title="!$vuetify.breakpoint.xs"
      >
        <v-tab v-if="!$vuetify.breakpoint.mobile" :to="{ name: 'EventList' }">
          Liste
        </v-tab>
        <v-tab :to="{ name: 'EventCalendar' }"> Kalender</v-tab>
        <v-tab :to="{ name: 'Holidays' }"> Ferien</v-tab>
      </v-tabs>
    </template>

    <template slot="help">
      <h3>Neuerungen</h3>
      <ul>
        <li>
          Neu steht die Terminliste auch in einer Kalenderansicht zur Verfügung.
        </li>
      </ul>
    </template>
    <router-view></router-view>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Events",
});
</script>
